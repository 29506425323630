@import 'assets/scss/variables';
@import 'assets/scss/functions';

.sash-horizontal {
  background-size: contain;
  background-repeat: repeat;
  background-color: rgba($secondary-background-color, $selected-opacity);
  height: 2px !important;
  transform: translateY(2px);

  &::before {
    display: none;
  }
}

.sash-button {
  @include stickToSide('bottom');

  height: 32px;
  left: 50%;
  transform: translate(-50%, -2px);
}
