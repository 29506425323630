@import 'assets/scss/variables';

@mixin scrollBars($color: $color-silver) {
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  // &::-webkit-scrollbar-button {
  //   height: 10px;
  // }

  &::-webkit-scrollbar-thumb {
    background: $color;
    border-radius: 3px;
    margin: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: darken($color: $color, $amount: 5%);
  }
}

%ex {
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 1px;
  color: $color-neon-carrot;
}

@mixin webkit-text-overflow($line-count: 1, $word-break: null) {
  display: -webkit-box;
  -webkit-line-clamp: $line-count;
  -webkit-box-orient: vertical;
  overflow: hidden;

  text-overflow: ellipsis;

  @if ($word-break !=null) {
    word-break: $word-break;
  } @else if $line-count ==1 {
    word-break: break-all;
  } @else {
    word-break: break-word;
  }
}

@mixin btnInteraction($background-color, $darkenAmount: 2) {
  transition: 0.2s cubic-bezier(0.3, 0, 0.5, 1);
  transition-property: color, background-color, border-color;

  &:hover {
    background-color: darken($background-color, $darkenAmount);
  }

  &:focus-visible,
  &:active {
    outline: 3px solid rgba($background-color, 0.3);
  }
}

@mixin Tab {
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 30px;
  cursor: pointer;
  color: $color-text-primary;
  background-color: $color-mystic;
  padding: 7px 9px 7px 9px;
  border-radius: 6px 6px 0 0;
  font-size: 12px;
  margin-right: 5px;
  z-index: 1;
  border: 1px solid transparent;
  cursor: pointer;

  &--selected {
    font-weight: 500;
    color: #fff;
    border: 1px solid $color-bondi-blue;
    background-color: $color-bondi-blue;
  }

  &:last-of-type {
    margin-right: 0;
  }
}

@mixin TabContainer {
  &__contacts-list {
    position: relative;
    display: flex;
    list-style: none;
    width: 100%;
    height: 30px;
  }

  &__contact-item {
    @include Tab();

    &:hover .chat__remove-img {
      display: block;
    }

    &:active {
      cursor: grabbing;
    }

    &--selected .chat__remove-img {
      color: #fff;
    }
  }

  &__contact-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__wrapper {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    align-items: stretch;
    position: relative;
  }
}

@mixin Pinned($background-color, $border-color) {
  display: flex;
  background-color: $background-color;
  z-index: 5;
  border-radius: 6px;
  flex-grow: 1;
  font-size: 11px;
  padding: 3px 5px;
  cursor: default;
  border: 1px solid $border-color;

  &__avatar-wrapper {
    margin: 2px 10px 2px 0;
    min-width: 36px;
    height: 36px;
    display: flex;
    gap: 5px;
  }

  &__avatar {
    border-radius: 50%;
    min-width: 36px;
    width: 36px;
    height: 36px;
  }

  &__icon {
    color: $border-color;
  }
}

@mixin ChatHeader($size) {
  &__header {
    padding: 8px 0 6px;
    background-color: $color-mystic;
  }
}

@mixin ModalWindow(
  $height,
  $width,
  $flex-direction,
  $extraStyles: null,
  $modificator: null,
  $border-location: 'bottom',
) {
  &#{$modificator} {
    height: $height;
    width: $width;
    display: flex;
    flex-direction: $flex-direction;
    background-color: $color-mystic;
    border-#{$border-location}: 2px solid $color-bondi-blue-dark;
    border-radius: 6px;
    font-family: Roboto, sans-serif;
    color: $color-gray;
    font-size: 14px;
    text-align: initial;
    overflow: hidden;
  }

  &#{$modificator} & { 
    @each $selector, $properties in $extraStyles {
      &__#{$selector} {
        @each $property, $value in $properties {
          #{$property}: $value
        }
      }
    }
  
    &__header,
    &__footer {
      display: flex;
    }
  
    &__main-container {
      background-color: $color-white;
      height: 100%;
      border-radius: 6px;
    }
  
    &__btn-container {
      display: flex;
      flex-direction: row;
    }
  }

}

@mixin MessageContainer($mode: null) {
  @if ($mode == 'active') {
    border-color: $color-border-focus;
  } @else if ($mode == 'light-background') {
    border-color: $color-gray;
  } @else {
    border-color: $color-white;
  }

  border-width: 1px;
  border-style: solid;
  border-radius: 6px;
  background: $color-white;

  &:focus-within {
    border-color: $color-border-focus;
  }
}

@mixin ModalWindowHeaderButtons {
  &__btn {
    display: flex;
    align-items: center;
    font-size: 12px;
    padding: 0;

    &--cancel {
      color: $color-persimmon;
    }

    &--submit {
      margin-left: 22px;

      &::after {
        content: "";
        mask-image: url(../icons/check.svg);
        mask-size: 20px;
        background-color: $color-bondi-blue;
        width: 20px;
        height: 20px;
        margin-left: 8px;
      }

      &:disabled {
        color: $color-gray;
        cursor: default;

        &::after {
          background-color: $color-gray;
        }
      }
    }
  }
}

%highlight {
  background-color: transparent;
  color: $color-red;
}


%inheritedColorsByIcon {
  &:has(#p) {
    --main-color: var(--pink-status-color);
  }

  &:has(#trash-transparent) {
    --main-color: var(--red-status-color);
  }

  &:has(#burger-menu) {
    --main-color: var(--tertiary-color);
  }
}

@mixin underliedBackground($radius: 0) {
  position: relative;
  z-index: $default-z-index;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background-color: rgb(var(--primary-color));
    z-index: $underlying-elements-z-index;
    border-radius: $radius;
  }
}

@mixin stickToSide ($side) {
  position: absolute;

  @if $side == 'left' {
    --is-sticked-to-side: 'left';
    left: 0;
  } @else if $side == 'right' {
    --is-sticked-to-side: 'right';
    right: 0;
  } @else if $side == 'top' {
    --is-sticked-to-side: 'top';
    top: 0;
  } @else if $side == 'bottom' {
    --is-sticked-to-side: 'bottom';
    bottom: 0;
  }
}

@keyframes shakeMe {
  0%,
  20%,
  40%,
  60%,
  80% {
    transform: translateX(-1px);
  }

  30%,
  50%,
  70%,
  10%,
  90% {
    transform: translateX(1px);
  }

  100% {
    transform: translateX(0px);
  }
}
