@import "assets/scss/variables.scss";

.profile-rates {
  $this: &;

  width: 370px;
  border-bottom: 2px solid $color-bondi-blue;
  box-shadow: $shadow-primary;
  border-radius: 6px;
  font-family: Roboto;
  background-color: $color-black-squeeze-light;
  z-index: $tooltips-z-index;
  cursor: default;

  &__title {
    padding: 9px 17px;
    border-radius: 6px 6px 0 0;
    background-color: $color-black-squeeze-light;
    text-align: left;
    color: $color-gray;
    font-size: 18px;
    line-height: 1;
  }
  &__edit-btn {
    position: absolute;
    display: flex;
    top: 5px;
    right: 15px;
    padding: 4px;
    font-size: 14px;
    color: $color-bondi-blue;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
    }
  }

  &__header {
    background-color: $color-white;
    color: $color-gray;
    font-size: 12px;
    display: flex;
    padding: 20px 0px 20px 17px;
  }
  &__header-item {
    text-transform: uppercase;
    text-align: center;
    width: 27%;

    &--type {
      width: 19%;
    }
    &--secret {
      color: $color-red;
    }
    &--member {
      color: $color-green-haze;
    }
    &:first-of-type {
      text-align: left;
    }
  }
  &__items-wrap {
    background-color: $color-black-squeeze-light;
    border-radius: 0 0 6px 6px;
    display: flex;
    justify-content: space-between;
  }
  &__items {
    margin: 0;
    padding-left: 17px;
    text-align: center;
    font-size: 10px;
    background-color: $color-black-squeeze-light;
    border-radius: 0 0 6px 6px;

    &:first-of-type {
      text-align: left;
      font-size: 11px;
      color: $color-gray;
    }
  }
  &__item {
    display: flex;
    font-size: 12px;
    text-align: center;
  }
  &__cell {
    display: flex;
    width: 27%;
    font-size: 10px;
    padding-top: 17px;
    color: $color-tundora;
    font-weight: 500;
    border-radius: 0 0 6px 0;

    #{$this}__item:last-child & {
      padding-bottom: 17px;
    }

    &-value {
      width: 50%;
    }
    &--name {
      text-align: left;
      font-size: 11px;
      width: 19%;
      font-weight: normal;
    }
    &--incall-outcall {
      background-color: $color-white;
    }
    &--member {
      color: $color-green-haze;
    }
    &--secret {
      background-color: $color-white;
      color: $color-red;
    }
  }
  &__discount {
    color: green;
  }
}
