@import 'assets/scss/variables';

.contact-tags {
  display: flex;
  flex-flow: row wrap;
  gap: 5px;

  .rc-overflow-item:not(.rc-overflow-item-rest):not(:nth-last-child(2)) > &__tag {
    margin-right: 5px;
  }

  &--no-tags {
    font-size: 11px;
    color: $color-gray;
  }
}
