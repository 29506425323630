@import 'src/assets/scss/variables.scss';

.tri-state-switch {
  --opacity: var(--selected-opacity);

  display: flex;
  overflow: hidden;
  border-radius: 9px;
  transition: all 0.4s ease-in-out;
  background-color: rgba(var(--gray-status-color), var(--opacity));
  height: 8px;
  width: 24px;
  z-index: $overlying-elements-z-index;

  &>input {
    width: 8px;
    height: 8px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background-color: rgb(var(--primary-color));
    transition: left .4s, right .4s, transform .4s;
  }

  &--off {
    background-color: rgba(var(--red-status-color), var(--opacity));

    &::before {
      left: 1px;
    }
  }

  &--waiting {
    &::before {
      right: 50%;
      transform: translate(50%, -50%);
    }
  }

  &--available {
    background-color: rgba(var(--secondary-color), var(--opacity));

    &::before {
      right: 1px;
    }
  }

  input {
    position: absolute;
    height: 0;
    width: 0;
    overflow: hidden;
  }

  label {
    padding: 5px 4px;
    transition: all 0.4s ease-in-out;
  }

  label:hover {
    cursor: pointer;
  }
}
