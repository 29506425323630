@import 'assets/scss/functions.scss';

.edit-contact-form {
  position: relative;
  color: $color-tundora;
  font-size: 10px;
  line-height: 1;
  letter-spacing: 0.83px;
  @include scrollBars;

  &__main {
    display: flex;
    flex-direction: column;
    padding-bottom: 24px;
  }

  &__error-msg {
    position: absolute;
    z-index: 1;
    left: 0;
    right: -97px;
    max-width: 214px;
    margin: auto;
    top: 78px;
    color: #F20C0C;
  }

  &__checkbox-wrapper {
    justify-content: space-around;
    display: flex;
    flex-direction: column;
  }

  &__checkbox-block {
    display: flex;
    gap: 10px;
  }

  &__information, &__types, &__girl-agent {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    gap: 5px;
    margin-bottom: 8px;
  }

  &__information .contact-form-via-item {
    display: flex;
  }

  &__girl-agent {
    padding-top: 14px;

    .react-select {
      $block: '.react-select';
      width: 100%;

      #{$block}__menu {
        left: -49px;
        width: calc(100% + 54px);
      }

      #{$block}__control {
        width: 100%;
        cursor: pointer;
      }

      #{$block}__single-value {
        text-align: center;
        font-weight: normal;
        color: $color-azure-radiance;
      }
    }
  }

  &__types {
    margin-top: 14px;
    gap: 15px;
  }

  &__spinner-wrapper {
    margin-top: 15px;
  }

  &__field {
    &-title {
      padding-right: 12px;
      color: $color-gray;
      font-size: 14px;
      line-height: 24px;
      font-family: Roboto;
      letter-spacing: 0;
      text-align: left;

      &--via {
        text-align: left;
      }
    }

    &--not-for {
      display: flex;
      flex-wrap: wrap;
      padding: 16px 23px 0;
    }

    &--via {
      padding: 16px 15px 10px;

      & .react-select__menu {
        right: auto;
      }

      .contact-form-via-item {
        &:not(:first-child) {
          margin-left: 18px;
        }

        &__btn-title {
          font-size: 12px;
        }
      }
    }

    &--contact-type, &--agent, &--category, &--type {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      width: 204px;
      margin-bottom: 0 !important;
      border: 1px solid $color-mystic;
      padding: 4px 5px 4px 9px;
      background-color: $color-white;
      border-radius: 6px;
      color: $color-gray;
      font-size: 14px;
      transition: all 0.15s ease-in-out;

      &:focus-within {
        border-color: $color-border-focus;
      }

      & .react-select__single-value {
        font-size: 10px;
        color: $color-bondi-blue;
      }

      & .react-select__dropdown-indicator {
        padding: 0;
        color: $color-gray;
      }

      & .react-select__single-value {
        font-size: 12px;
      }
    }

    &--agent {
      width: 100%;
    }

    &--type {
      width: 50%;
      justify-content: flex-start;
      gap: 5px;

      .react-select {
        $block: '.react-select';
        width: 100%;

        #{$block}__menu {
          left: -49px;
          width: calc(100% + 54px);
        }

        #{$block}__control {
          width: 100%;
          cursor: pointer;
        }

        #{$block}__single-value {
          text-align: start;
          font-weight: normal;
          color: $color-azure-radiance;
        }
      }
    }

    &--subtype {
      width: 50%;

      .react-select {
        $block: '.react-select';

        #{$block}__menu {
          left: -70px;
          width: 156px;
        }
      }
    }

    &--category {
      width: 50px;
      padding: 4px;

      .react-select {
        margin-left: 0;
        $block: '.react-select';

        #{$block}__control {
          cursor: pointer;
        }

        #{$block}__menu {
          left: -5px;
          width: 43px;
        }

        #{$block}__single-value {
          color: $color-gray;
        }

        #{$block}__dropdown-indicator {
          padding: 0;

          svg {
            width: 16px;
            height: 16px;
          }
        }
      }
    }

    &--disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  &__input-wrap {
    display: flex;
    position: relative;
    margin-top: 14px;
    padding: 0 20px;
    height: 40px;

    &--description {
      width: 100%;
    }
  }

  &__girl-fn {
    line-height: 16px;
    color: $color-black;
    max-height: 62px;
    -webkit-line-clamp: 4;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__input-title {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 29px;
    height: fit-content;
    margin: auto;
    color: $color-gray;
    font-weight: 400;
    font-size: 14px;
    line-height: 1;
    letter-spacing: 1px;
    z-index: 3;
  }

  &__btn-clear {
    display: flex;
    z-index: 2;
    position: absolute;
    top: 11px;
    right: 26px;
    padding: 6px;

    &-icon {
      width: 8px;
      height: 8px;
      color: $color-gray;
    }
  }

  &__header {
    display: flex;
    padding: 0 20px;
    align-items: center;
  }

  &__via-list {
    display: flex;
    margin-top: 14px;
    gap: 15px;
  }

  &__avatar-wrapper {
    position: relative;
    width: 90px;
    height: 90px;
    border-radius: 50%;
  }

  &-avatar {
    &__icon {
      opacity: 0;
      color: $color-white;
      cursor: pointer;
      transition: opacity 0.2s linear, background-color 0.2s linear;
    }

    &__control-icons {
      display: flex;
      z-index: 5;
      position: absolute;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      width: 90px;
      height: 90px;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0);
      transition: opacity 0.2s linear, background-color 0.2s linear;
    }

    &__image-input {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      outline: none;
      cursor: pointer;
      gap: 8px;
    }

    &__img-wrap {
      width: 90px;
      height: 90px;
      border-radius: 50%;

      .lazy-load__container {
        width: 90px;
        height: 90px;
        overflow: hidden;
      }
    }

    &__img-container {
      display: flex;
      border-radius: 50%;
      padding-bottom: 10px;

      &:hover {
        & .edit-contact-form-avatar {
          &__control-icons {
            background-color: rgba(0, 0, 0, 0.8);
          }

          &__icon {
            opacity: 1;
          }
        }
      }
    }
  }

  .contact-address-tab__input-wrap {
    &--address {
      margin: 0 0 15px 0;
      padding: 0;
    }
  }

  &__id {
    flex: 2;
    padding: 12px 10px;
    border-radius: 6px;
    border: 1px solid $color-mystic;
    color: $color-tundora;
    font-size: 14px;
    text-align: left;
    letter-spacing: 1px;
    background-color: $color-white;
    margin-right: 15px;
  }

  &__header-group {
    display: flex;
    position: relative;
    flex-grow: 1;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    margin: 0;
    margin-left: 11px;
    color: $color-gray;
    font-size: 14px;
    gap: 4px;
  }

  .form-error-text__wrap {
    position: absolute;
    right: 0;
    bottom: -14px;
    left: 0;
    color: $color-red;
    font-weight: 300;
    font-size: 11px;
  }

  &__tel,
  &__mail,
  &__telegram-nickname {
    display: flex;
    position: relative;
    align-items: center;
    width: 100%;

    &:not(:first-child) {
      margin: 20px 0 0;
    }
  }

  &__tel-wrapper {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }

  &__textarea-wrapper {
    flex-grow: initial;
    margin-bottom: 10px;
    padding: 9px;
    border: 1px solid $color-mystic;
    border-radius: 6px;
    background-color: $color-white;
    transition: all 0.15s ease-in-out;
    height: 100%;

    &:focus-within {
      border-color: $color-border-focus;
    }

    &--short-name {
      position: relative;
      &::before {
        content: 'Name:';
        position: absolute;
        letter-spacing: 1px;
        left: 10px;
        z-index: 2;
        top: 48%;
        transform: translateY(-50%);
      }
    }

  }

  &__phone-numbers,
  &__mails,
  &__telegram-nickname {
    width: 100%;
  }

  &__phone-numbers {
    padding: 18px 19px 8px;
  }

  &__mails {
    padding: 0 19px 8px;
  }

  &__telegram-nicknames {
    padding: 0 19px 18px;
    border-bottom: 1px solid $color-gray;
  }

  &__addresses {
    padding: 0 !important;
    border-bottom: 1px solid $color-gray;
    text-align: left;
    margin-bottom: 5px;
  }

  &__field-content {
    display: flex;
    align-items: center;
  }

  &__field-type-error {
    position: absolute;
    bottom: -13px;
    right: 20px;
    font-size: 13px;
    color: #FF5E57;
  }

  &__input-field-btn {
    display: flex;
    position: absolute;
    top: 50%;
    justify-content: center;
    align-items: center;
    padding: 0;
    color: $color-gray;
    transform: translateY(-50%);

    &--field-action {
      right: 30px;

      & > svg {
        width: 12px;
        height: 12px;
      }
    }

    &--del {
      z-index: 1;
      right: 8px;
      padding: 5px;
      color: $color-persimmon;

      & > svg {
        width: 8px;
        height: 8px;
      }
    }
  }

  &__add-field-btn {
    display: flex;
    position: relative;
    align-items: center;
    margin: 10px 0;
    color: $color-gray;
    font-size: 14px;

    &::after {
      content: "Add Number";
      display: block;
      position: absolute;
      left: 25px;
      width: 110px;
      padding-left: 10px;
      text-align: left;
    }

    &--mail-add {
      &::after {
        content: "Add Email";
      }
    }

    &--telegram-add {
      white-space: nowrap;
      &::after {
        content: "Add telegram nickname";
      }
    }

    &--contact-add {
      margin: 6px 0;
      padding: 0;

      &::after {
        padding-left: 2px;
        content: "Add Contact";
      }
    }

    & > svg {
      width: 20px;
      height: 20px;
      fill: $color-bondi-blue;
    }
  }

  &__btn {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;

    &-wrapper {
      flex-basis: 100%;
    }

    &-undo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-items: center;
      width: 100%;
      max-width: 180px;
      margin: 13px auto 0 19px;
      padding: 5px 16px;
      border: 1px solid $color-silver;
      border-radius: 6px;
      font-size: 14px;
      line-height: 20px;
      text-align: left;
      letter-spacing: 0.88px;
      background-color: $color-mystic;

      & > svg {
        width: 12px;
      }
    }
  }

  &__input {
    width: 100%;
    padding: 9px 75px 9px 10px;
    border: 1px solid $color-mystic;
    border-radius: 6px;
    outline: none;
    color: $color-tundora;
    font-size: 14px;
    line-height: 19px;
    font-family: Roboto;
    letter-spacing: 0;
    transition: all 0.15s ease-in-out;
    @include scrollBars;

    &--error {
      border: 1px solid $color-red !important;
    }

    &--fn {
      height: 18px;
      min-height: 18px;
      max-height: 40px;
      padding: 0;
      border: none;
      resize: none;
    }

    &--emoji {
      width: 190px;
    }

    &--short-name {
      margin-bottom: 5px;
    }

    &--fb-letters, &--location, &--emoji, &--nationality, &--short-name, &--prices {
      padding: 0;
      position: relative;

      &::before {
        position: absolute;
        letter-spacing: 1px;
        left: 10px;
        z-index: 2;
        top: 50%;
        transform: translateY(-50%);
        color: $color-gray;
      }

      & .input {
        position: relative;
        border: 1px solid transparent;
        // padding-left: 85px;
      }

      &:focus-within {
        border-color: $color-border-focus;
      }
    }

    &--short-name {
      &::before {
        content: 'Name:';
      }

      & .input {
        padding-left: 55px;
      }
    }

    &--prices {
      &::before {
        content: 'Prices:';
      }

      & .input {
        padding-left: 58px;
      }
    }

    &--fb-letters {
      &::before {
        content: 'FB letters:';
      }

      & .input {
        padding-left: 84px;
      }
    }

    &--location {
      &::before {
        content: 'Location:';
      }

      & .input {
        padding-left: 77px;
      }
    }

    &--nationality {
      &::before {
        content: 'Nat-ty:';
      }

      & .input {
        padding-left: 57px;
      }
    }

    &--emoji {
      &::before {
        content: 'Emoji:';
      }

      & .input {
        padding-left: 57px;
      }
    }

    &--description {
      width: 100%;
      display: flex;
      position: relative;
      align-items: center;
      padding: 0;
      border: none;

      & .input {
        border: 1px solid $color-white;
        background: $color-white;
        padding: 11px 23px 11px 100px;
        text-overflow: ellipsis;
        font-weight: bold;

        &:focus-visible {
          border-color: $color-border-focus;
        }
      }
    }

    &--custom_id {
      padding: 0;
      width: 155px;

      &::before {
        content: 'ID:';
        position: absolute;
        letter-spacing: 1px;
        left: 10px;
        z-index: 2;
        top: 48%;
        transform: translateY(-50%);
      }

      & .input {
        position: relative;
        border: 1px solid transparent;
        padding-left: 34px;

        &:focus-visible {
          border-color: $color-border-focus;
        }

      }
    }

    &:focus-visible {
      border-color: $color-border-focus;
    }
  }

  &__input-wrapper {
    display: flex;
    position: relative;
    flex-grow: 1;
    flex-direction: column;
  }

  &__select-tel-label {
    width: 60px;

    &--pending {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 45px;
      border: 1px solid $color-wild-sand;
      background-color: $color-wild-sand;
    }

    & menu {
      width: 200px;
    }
  }

  &-select__control {
    width: 70px;
  }

  &-select__menu {
    width: 160px;
  }

  &-select__single-value {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .tags-field {
    margin: 10px 0;

    &__value-container {
      min-height: 70px;
      text-align: left;
      align-content: baseline;
    }

    &__control {
      border: 1px solid transparent;
      background-color: $color-white;
      box-shadow: none;
      transition: all 0.15s ease-in-out;

      &:focus-within {
        border-color: $color-border-focus;
      }
    }

    &__multi-value {
      &__remove:hover {
        color: $color-tundora;
        background-color: $color-geyser;
      }
    }

    &-container {
      padding: 14px 21px 22px;
      border-bottom: 1px solid $color-gray;
    }

    &__menu {
      top: auto;
      right: 0;
      left: 0;
      width: 328px;
      margin: auto;
    }
  }

  &__notes-textarea-container {
    height: 150px;
    padding: 14px;

    & textarea {
      height: 100%;
    }
  }

  &__agent-btn {
    width: 100%;
  }
}
