@import 'assets/scss/variables.scss';
@import 'assets/scss/functions.scss';

.grouped-buttons {
  $theme-dim-background-color: rgba($tertiary-background-color,
      $strong-highlighted-opacity );
  $theme-dim-selected-button-color: rgba($secondary-background-color,
      $selected-opacity );
  $theme-transparent-button-border-color: rgba($tertiary-background-color,
      $light-highlighted-opacity + $difference-opacity );
  $theme-transparent-button-background-color: rgba($tertiary-background-color,
      $light-highlighted-opacity );

  display: flex;
  flex-direction: row;
  transition: all $default-animation;
  height: 100%;

  &--theme-light {
    background-color: $primary-background-color;
    border-radius: 0 0 $radius-m $radius-m;
  }

  :is(&--theme-dim, &--theme-dim-animated) {
    border-radius: $radius-m;

    &>.dropdown-button> :where(a.active, button.active) {
      border-radius: $radius-m;
    }
  }

  :is(&--theme-dim, &--theme-dim-animated) :is(&__button, &__dropdown-button) {
    background-color: $theme-dim-background-color;

    &.active {
      background-color: $theme-dim-selected-button-color;
    }

    &:first-child {
      border-top-left-radius: $radius-m;
      border-bottom-left-radius: $radius-m;
    }

    &:last-child {
      border-top-right-radius: $radius-m;
      border-bottom-right-radius: $radius-m;
    }
  }

  &--theme-dim-animated &__button {
    transition: background-color $default-animation, border-color $default-animation;

    &:where(:hover, &.active):not(.disabled) {
      background-color: $theme-transparent-button-background-color;
      border: 2px solid $theme-transparent-button-border-color;
    }

    &:first-child {
      border-top-left-radius: $radius-m;
      border-bottom-left-radius: $radius-m;
    }

    &:last-child {
      border-top-right-radius: $radius-m;
      border-bottom-right-radius: $radius-m;
    }
  }

  &__null-box {
    height: 100%;
    width: 100%;
    aspect-ratio: 1;
  }

  &>.dropdown-button {
    position: relative;
    z-index: 2;
    background-color: $color-morning-glory;

    & svg {
      width: 20px;
      height: 20px;
      max-width: 20px;
      max-height: 20px;
    }
  }

  & .grouped-menu {
    width: 100%;
    height: auto;
    position: absolute;
    z-index: $floating-buttons-z-index;
    flex-direction: column;
    opacity: 0;
    visibility: hidden;
    top: 45px;
    transform: translateY(-100%);
    transition: all $default-animation;

    & svg {
      width: 20px;
      height: 20px;
      max-width: 20px;
      max-height: 20px;
    }

    &.visible {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
      box-shadow: $default-floating-box-shadow;
    }
  }

  & .grouped-menu:is(&--theme-dim, &--theme-dim-animated) &__button {
    border-radius: 0;

    &:first-child {
      border-top-left-radius: $radius-m;
      border-top-right-radius: $radius-m;
    }

    &:last-child {
      border-bottom-left-radius: $radius-m;
      border-bottom-right-radius: $radius-m;
    }
  }

  & .grouped-menu &__button {
    width: 100%;
    aspect-ratio: 1;
  }

  &__dropdown-button {
    position: relative;
  }

  &::before &__dropdown-button {
    content: "";
    position: static;
  }
}
