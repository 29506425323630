@import 'assets/scss/functions.scss';

.navbar {
  height: 56px;

  @include underliedBackground($radius-m);
  
  &-dropdowned {
    height: 40px;
    aspect-ratio: 1;
  }

  &__dropdown {
    @include underliedBackground($radius-m);
  }
}
